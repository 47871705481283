//import vue router
import { createRouter, createWebHistory } from 'vue-router';

//import axios
import axios from 'axios';

//default base URL / EndPoint API
axios.defaults.baseURL = "https://adm-dishub.kotabogor.go.id/";
// axios.defaults.baseURL = "https://admin-dpupr.kotabogor.go.id";

//define a routes
const routes = [
    {
        path: '/',
        name: 'home',
        component: () => import( /* webpackChunkName: "Home" */ "@/views/home/Index"),
    },
    {
        path: '/profil/sambutan',
        name: 'Sambutan',
        component: () => import( /* webpackChunkName: "Profil Sambutan" */ "@/views/profil/Sambutan"),
    },
    {
        path: '/profil/visimisi',
        name: 'Visi dan Misi',
        component: () => import( /* webpackChunkName: "Profil Visi Misi" */ "@/views/profil/Visimisi"),
    },
    {
        path: '/profil/datapejabat',
        name: 'Data Pejabat',
        component: () => import( /* webpackChunkName: "Profil Data Pejabat" */ "@/views/profil/Datapejabat"),
    },
    {
        path: '/profil/struktur',
        name: 'Struktur Organisasi',
        component: () => import( /* webpackChunkName: "Profil Struktur" */ "@/views/profil/Struktur"),
    },
    {
        path: '/profil/tupoksi',
        name: 'Tupoksi',
        component: () => import( /* webpackChunkName: "Profil Tupoksi" */ "@/views/profil/Tupoksi"),
    },
    // {
    //     path: '/profil/pelaksana',
    //     name: 'Pelaksana',
    //     component: () => import( /* webpackChunkName: "Profil Data Pejabat" */ "@/views/profil/Pelaksana"),
    // },
    {
        path: '/berita',
        name: 'Berita',
        component: () => import( /* webpackChunkName: "BeritaIndex" */ "@/views/berita/Index"),
    },
    {
        path: '/berita/kategori/:id',
        name: 'Kategori Berita',
        component: () => import( /* webpackChunkName: "KategoriBerita" */ "@/views/berita/Kategori"),
    },
    {
        path: '/berita/kategori/:kategori/:slug',
        name: 'detail_berita',
        component: () => import( /* webpackChunkName: "BeritaShow" */ "@/views/berita/Show")
    },
    {
        path: '/tag/:slug',
        name: 'detail_tag',
        component: () => import( /* webpackChunkName: "TagShow" */ "@/views/tag/Show")
    },
    {
        path: '/category/:slug',
        name: 'detail_category',
        component: () => import( /* webpackChunkName: "CategoryShow" */ "@/views/category/Show")
    },
    {
        path: '/dokumen',
        name: 'Dokumen',
        component: () => import( /* webpackChunkName: "DokumenIndex" */ "@/views/dokumen/Index")
    },
    {
        path: '/event/:slug',
        name: 'detail_event',
        component: () => import( /* webpackChunkName: "DokumenShow" */ "@/views/dokumen/Show")
    },
    {
        path: '/album',
        name: 'Album',
        component: () => import( /* webpackChunkName: "AlbumIndex" */ "@/views/album/Index")
    },
    {
        path: '/album/:id',
        name: 'Album Foto',
        component: () => import( /* webpackChunkName: "AlbumFoto" */ "@/views/album/Foto")
    },
    {
        path: '/video',
        name: 'Video',
        component: () => import( /* webpackChunkName: "VideoIndex" */ "@/views/video/Index")
    },
    {
        path: '/kontak',
        name: 'Kontak',
        component: () => import( /* webpackChunkName: "KontakIndex" */ "@/views/kontak/Index")
    },
    {
        path: '/kontak/whatsapp',
        name: 'WhatsApp',
        component: () => import( /* webpackChunkName: "Kontak Whatsapp" */ "@/views/kontak/Whatsapp"),
    },
    {
        path: '/kontak/instagram',
        name: 'Instagram',
        component: () => import( /* webpackChunkName: "Kontak Instagram" */ "@/views/kontak/Instagram"),
    },
    {
        path: '/banner',
        name: 'banner',
        component: () => import( /* webpackChunkName: "BannerIndex" */ "@/views/banner/Index")
    },
    {
        path: '/pengaduan/sibadra',
        name: 'SiBadra',
        component: () => import( /* webpackChunkName: "Pengaduan SiBadra" */ "@/views/pengaduan/Sibadra"),
    },
    {
        path: '/pengaduan/sp4n-lapor',
        name: 'SP4N Lapor',
        component: () => import( /* webpackChunkName: "Pengaduan SP4N Lapor" */ "@/views/pengaduan/Span"),
    },
]

//create router
const router = createRouter({
    history: createWebHistory(),
    routes // <-- routes
})

router.beforeEach((to, from, next) => {
    if(to.name == 'home') {
        document.title = 'Dinas Tenaga Kerja Kota Bogor'
    }
    else {
        document.title = to.name + ' | ' +' Dinas Tenaga Kerja Kota Bogor'
    }
    next();
  });

export default router